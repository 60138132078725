<template>
  <div>
    <v-bottom-navigation fixed :value="nav" color="primary" grow>
      <v-btn color="primary" text @click="changeTab(0)">
        <span>Pending</span>
        <v-icon>mdi-account-alert</v-icon>
      </v-btn>
      <v-btn color="primary" text @click="changeTab(1)">
        <span>Upcoming</span>
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
      <v-btn color="primary" text @click="changeTab(2)">
        <span>Completed</span>
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-container class="list-wrapper" fluid>
      <v-row dense>
        <v-progress-circular
          v-if="loading"
          :size="50"
          color="primary"
          indeterminate
          class="progress"
        ></v-progress-circular>

        <template v-else-if="activeAssignments.length">
          <v-col cols="12" v-for="a of activeAssignments" :key="a.id">
            <assignment-list
              class="d-flex d-sm-none"
              :assignment="a"
              :tripRequest="tripRequests.find((e) => e.id == a.tripRequestId)"
            ></assignment-list>
            <assignment
              class="d-none d-sm-flex"
              :assignmentProp="a"
              :allExpanded="false"
              :specialIndicators="specialIndicators"
              :config="config"
              @refresh="refresh"
            ></assignment>
          </v-col>
        </template>
        <v-col cols="12" v-else>
          <div class="text-subtitle-1 text-center">No {{ types[nav] }} assignments yet</div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="driverTableDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="driverTableDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Driver Table</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <driver-table></driver-table>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format, fromUnixTime } from 'date-fns';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { GET_MY_ASSIGNMENTS } from '@/store/modules/Assignment/actions';
import { GET_CONFIG, GET_SPECIAL_INDICATORS } from '@/store/modules/Config/actions';
import AssignmentList from './Assignment.List.vue';
import Assignment from '../Assignments/Assignment.vue';

import DriverTable from '../Tables/DriverTable.vue';

export default {
  inject: ['eventHub'],
  components: { AssignmentList, Assignment, DriverTable },
  data() {
    return {
      format,
      fromUnixTime,
      nav: 0,
      assignments: [],
      activeAssignments: [],
      selectedItem: null,
      types: { 0: 'Pending', 1: 'Upcoming', 2: 'Completed' },
      loading: false,
      driverTableDialog: false,
    };
  },
  computed: {
    ...mapGetters('tripRequest', ['tripRequests']),
  },
  created() {
    this.eventHub.$on('openDriverTable', () => this.openDriverTable());
    this.eventHub.$on('openDriverExpDates', () => this.openDriverExpDates());
    this.eventHub.$on('openDriverEvents', () => this.openDriverEvents());
    this.getItems();
  },
  beforeDestroy() {
    this.eventHub.$off('openDriverTable');
    this.eventHub.$off('openDriverExpDates');
    this.eventHub.$off('openDriverEvents');
  },
  methods: {
    ...mapActions('assignment', [GET_MY_ASSIGNMENTS]),
    ...mapActions('config', [GET_CONFIG, GET_SPECIAL_INDICATORS]),
    async getItems() {
      this.loading = true;
      this.assignments = await this.getMyAssignments();
      this.specialIndicators = await this.getSpecialIndicators();
      this.config = await this.getConfig('tripRequestForm');
      this.changeTab(0);
      this.loading = false;
    },
    async refresh() {
      this.loading = true;
      this.assignments = await this.getMyAssignments();
      this.changeTab(0);
      this.loading = false;
    },
    changeTab(index) {
      this.nav = index;
      if (index == 0) this.activeAssignments = this.assignments.filter((e) => e.status == 0);
      else if (index == 1)
        this.activeAssignments = this.assignments.filter(
          (e) => e.status == 1 && this.tripRequests.find((r) => r.id == e.tripRequestId).status != 2
        );
      else if (index == 2)
        this.activeAssignments = this.assignments.filter(
          (e) => e.status == 1 && this.tripRequests.find((r) => r.id == e.tripRequestId).status == 2
        );
    },
    openDriverTable() {
      this.driverTableDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.list-wrapper {
  padding-bottom: 56px;
}
.progress {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.v-progress-circular > svg {
  width: fit-content;
}
.right {
  float: right;
}
</style>
