<template>
  <v-badge
    :value="!!assignment.needsAttention"
    color="error"
    icon="mdi-exclamation-thick"
    left
    overlap
    bordered
    class="full-width"
  >
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="12" lg="4">
        <v-card class="mb-4">
          <v-container fluid class="pa-0" :class="`${this.status.color.split(' ')[0]}-border `">
            <v-row
              class="ma-0 clickable"
              :class="`${this.status.color} white--text ${this.status.color.split(' ')[0]}-border-bottom`"
              @click="$router.push(`/driver/${assignment.id}`)"
            >
              <v-col cols="auto" class="text-center">
                <div class="text-subtitle-1">TRIP #</div>
                <div class="text-h5">{{ assignment.tripRequestId }}</div>
                <v-icon
                  :class="`${status.color.split(' ')[0]}--text text--${status.color.split(' ')[1]} mx-1`"
                  v-if="assignment.outOfState"
                >
                  mdi-weather-night
                </v-icon>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="8" class="right">
                <div class="text-subtitle-1 text-right"><v-icon color="white">mdi-account</v-icon>{{ driver }}</div>
                <div class="text-subtitle-1 text-right"><v-icon color="white">mdi-bus</v-icon>{{ vehicle }}</div>
              </v-col>
            </v-row>
            <v-row class="px-2 my-2">
              <v-col cols="6">
                <div class="text-subtitle-1">{{ leaveDate }} - {{ leaveTime }}</div>
                <div class="text-subtitle-1">{{ returnDate }} - {{ returnTime }}</div>
              </v-col>
              <v-col cols="6">
                <div class="text-subtitle-1 text-right">{{ tripHours }} TOTAL HRS</div>
                <div class="text-subtitle-1 text-right">TOTAL MI</div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-2 my-2">
              <v-col cols="5" class="center-vertical">
                <div class="text-subtitle-1">{{ locationName }}</div>
              </v-col>
              <v-col cols="2" class="center-vertical">
                <v-icon :color="this.status.color">mdi-arrow-right</v-icon>
              </v-col>
              <v-col cols="5" class="center-vertical">
                <div class="text-subtitle-1 text-right">{{ destinationName }}</div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-badge>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { format, fromUnixTime, differenceInMinutes } from 'date-fns';

export default {
  name: 'AssignmentListMobile',
  inject: ['eventHub'],
  components: {},
  props: {
    assignment: Object,
    tripRequest: Object,
  },
  data() {
    return {
      format,
      fromUnixTime,
      differenceInMinutes,
      expand: false,
      color: 'primary',
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'usersByEmail']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('destination', ['destinations']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    status() {
      if (!this.assignment.status) return { display: 'pending', color: 'blue darken-2' };
      if (this.assignment.status == 1 && this.tripRequest.status == 2)
        return { display: 'complete', color: 'orange darken-3' };
      else return { display: 'upcoming', color: 'green darken-1' };
    },
    driver() {
      return this.assignment.driverId ? this.getDriverInfo(this.assignment.driverId) : this.assignment.driver;
    },
    vehicle() {
      return this.vehiclesById[this.assignment.vehicleId].name;
    },
    leaveDate() {
      const d = new Date(this.assignment.leaveDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'LLL dd');
    },
    leaveTime() {
      const s = this.assignment.leaveTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    returnDate() {
      const d = new Date(this.assignment.returnDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'LLL dd');
    },
    returnTime() {
      const s = this.assignment.returnTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    tripHours() {
      const start = new Date(`${this.assignment.leaveDate}, ${this.assignment.leaveTime}`);
      const end = new Date(`${this.assignment.returnDate}, ${this.assignment.returnTime}`);
      const diff = differenceInMinutes(end, start);
      return Math.round((diff / 60 + Number.EPSILON) * 100) / 100;
    },
    locationName() {
      return this.locationsById[this.tripRequest.locationId].name;
    },
    destinationName() {
      if (this.destinations && this.destinations.length) {
        const found = this.destinations.find((e) => e.id == this.tripRequest.destinationId);
        return found ? found.name : '-';
      } else return '';
    },
  },
  methods: {
    getDisplayDate(date) {
      const d = new Date(date);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'MMM d, yyyy');
    },
    getDriverInfo(driverId) {
      return `${this.driversById[driverId].firstName} ${this.driversById[driverId].lastName}`;
    },
  },
};
</script>

<style lang="scss">
.full-width {
  width: 100%;
}
.clickable:hover {
  cursor: pointer;
}
.green-border {
  border: 5px solid #43a047 !important;
}
.orange-border {
  border: 5px solid #ef6c00 !important;
}
.blue-border {
  border: 5px solid #1976d2 !important;
}
.red-border {
  border: 5px solid #ff5252 !important;
}
.green-border-bottom {
  border-bottom: 5px solid #43a047 !important;
}
.orange-border-bottom {
  border-bottom: 5px solid #ef6c00 !important;
}
.blue-border-bottom {
  border-bottom: 5px solid #1976d2 !important;
}
.red-border-bottom {
  border-bottom: 5px solid #ff5252 !important;
}
.center-vertical {
  align-self: center;
}
</style>
